.outerLoading {
  display: table;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  position: absolute;
  //background: linear-gradient(to bottom,#0099cc 0%,#00ccff 100%);
  background: #ffffff;
}
.innerLoading {
  display: table-cell;
  vertical-align: middle;
}
.innerLoading > .loading {
  text-align: center;
}
