.thank {
  text-align: center;
}
.thank > img {
  padding: 30px 20px;
  width: 220px;
}
.thank > .title {
  color: #000000;
  font-weight: bold;
  font-size: 43px;
}
.thank > .sub {
  color: #a5a5a5;
  font-size: 21px;
}
