.nps-rating {
  padding: 10% 0;
  text-align: center;
}
.nps-rating li {
  display: inline-block;
  cursor: pointer;
  width: 7%;
  border-top: 1px solid #7d7d7d;
  border-bottom: 1px solid #7d7d7d;
  border-left: 1px solid #7d7d7d;
  line-height: 35px;
  min-width: 10px;
}
.nps-rating li:hover {
  background-color: #eff0f1;
}
.nps-rating li:last-child {
  border-right: 1px solid #7d7d7d;
}
.npm_question_element {
  width: 80%;
  margin: 0 auto;
}
.npm_question_element_left {
  display: inline-block;
  width: 50%;
  text-align: left;
}
.npm_question_element_right{
  display: inline-block;
  width: 50%;
  text-align: right;
}

