.rating_numpad {
  margin-top: 8px;

  .rating_numpad_row {
    display: grid;
    grid-template-columns: repeat(10, 1fr);

    &.pattern_full {
      grid-template-columns: repeat(11, 1fr);
    }

    .rating_numpad_col {
      display: flex;
      justify-content: center;
      align-items: center;

      .rating_numpad_btn {
        margin: 6px 3px;
        border-radius: 8px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        max-width: 48px;
        max-height: 48px;
        min-width: 26px;
        min-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &[is-deactive=""] {
          background-color: #e7efe8;

          &.default2 {
            color: #007360;
            background: #e7efe8;
          }

          &.default3 {
            color: #000;
            border: 1px solid #c9c9c9;
            background: transparent;
          }

          &.default4 {
            color: #000;
            background: #e7efe8;
            color: #00a4e4;
          }
        }

        &.default2,
        &.default4 {
          &[is-active="0"],
          &[is-active="1"],
          &[is-active="2"],
          &[is-active="3"],
          &[is-active="4"],
          &[is-active="5"],
          &[is-active="6"],
          &[is-active="7"],
          &[is-active="8"],
          &[is-active="9"],
          &[is-active="10"] {
            background-color: #007360;
            color: #fff;
          }
        }

        &[is-active="0"] {
          background-color: #f45235;
          color: #fff;
        }

        &[is-active="1"] {
          background-color: #f45235;
          color: #fff;
        }

        &[is-deactive="2"],
        &[is-active="2"] {
          background-color: #f7702f;
          color: #fff;
        }

        &[is-deactive="3"],
        &[is-active="3"] {
          background-color: #f98928;
          color: #fff;
        }

        &[is-deactive="4"],
        &[is-active="4"] {
          background-color: #fa9f20;
          color: #fff;
        }

        &[is-deactive="5"],
        &[is-active="5"] {
          background-color: #fdb714;
          color: #fff;
        }

        &[is-deactive="6"],
        &[is-active="6"] {
          background-color: #f4c707;
          color: #fff;
        }

        &[is-deactive="7"],
        &[is-active="7"] {
          background-color: #bac10c;
          color: #fff;
        }

        &[is-deactive="8"],
        &[is-active="8"] {
          background-color: #7bbd0c;
          color: #fff;
        }

        &[is-deactive="9"],
        &[is-active="9"] {
          background-color: #4eba0a;
          color: #fff;
        }

        &[is-deactive="10"],
        &[is-active="10"] {
          background-color: #4eba0a;
          color: #fff;
        }
      }
    }
  }
}

.rating_numpad_label {
  margin-top: 8px;

  .rating_numpad_label_row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rating_numpad_label_col {
      display: flex;
      align-items: center;
    }

    .text_left {
      color: #f45235;
    }

    .text_right {
      text-align: right;
      color: #4eba0a;
    }

    @media (max-width: 375px) {
      .text_left {
        font-size: 12px;
      }

      .text_right {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .rating_numpad {
    .rating_numpad_row {
      .rating_numpad_col {
        .rating_numpad_btn {
          min-width: 24px;
          min-height: 45px;
        }
      }
    }
  }

  .page {
    margin: 0 !important;

    .page_body {
      &.p-sm {
        padding: 0 3%;
        margin: 0;
        min-height: 300px;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .rating_numpad {
    .rating_numpad_row {
      &.pattern_full {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
}

@media screen and (max-width: 319px) {
  .rating_numpad {
    .rating_numpad_row {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.nomood {
  background-image: url("../assets/images/Icon_Smily_Face-01.png");
  width: 25px;
  height: 25px;
  background-size: cover;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 5px;
}

.hasmood {
  background-image: url("../assets/images/Icon_Smily_Face-02.png");
  width: 25px;
  height: 25px;
  background-size: cover;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
}
