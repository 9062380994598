@font-face {
  font-family: "Kanit";
  src: url("../assets/fonts/Kanit-Light.woff");
}

.f404-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.f404-container {
  width: 100%;
  background-color: #fff;
  flex: 1;
  margin: 0 auto;
}

.f404 {
  padding-bottom: 50px;
}

.f404 .ocean {
  /*background-color: #0c8da0;*/
  height: 315px;
  text-align: center;
  padding-top: 90px;
}

.f404 .ocean img {
  margin-right: auto;
  margin-left: auto;
  width: 150px;
}

.f404 .text {
  display: block;
  // margin-top: -180px;
}

.f404 .t1 {
  font-weight: bold;
  font-size: 26px;
  color: #001c20;
  text-align: center;
}

.f404 .t2 {
  font-size: 24px;
  color: #001c20;
  text-align: center;
}

.f404 .t3 {
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  color: #001c20;
  text-align: center;
}

.error500{
  width: 500px;
}
