@font-face {
  font-family: 'Kanit';
  src: url('./assets/fonts/Kanit-Light.woff');
}
@font-face {
  font-family: 'ThaiSans Neue';
  src: url('./assets/fonts/ThaiSansNeue-Regular.woff');
}
@font-face {
  font-family: 'DB_ThaiText_X';
  src: url('./assets/fonts/DBThaiTextX.woff');
}
@font-face {
  font-family: 'Khmer';
  src: url('./assets/fonts/Khmer.ttf'),
    url('./assets/fonts/khmer.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Zawgyi-One';
  src: url('./assets/fonts/ZawgyiOne.ttf'),
    url('./assets/fonts/zawgyione.eot') format('embedded-opentype');
}

//Add new Font for Project Falcon

@font-face {
  font-family: 'Angsana';
  src: url('./assets/fonts/Angsana\ New\ V2\ V1.woff');
}

@font-face {
  font-family: 'Browallia';
  src: url('./assets/fonts/Browallia\ New.woff');
}

@font-face {
  font-family: 'Calibri';
  src: url('./assets/fonts/Calibri.woff');
}

@font-face {
  font-family: 'DilleniaUPC';
  src: url('./assets/fonts/DilleniaUPC\ V2.woff');
}

@font-face {
  font-family: 'EucrosiaUPC';
  src: url('./assets/fonts/EucrosiaUPC.woff');
}

@font-face {
  font-family: 'EucrosiaUPC';
  src: url('./assets/fonts/EucrosiaUPC.woff');
}

@font-face {
  font-family: 'FreesiaUPC';
  src: url('./assets/fonts/FreesiaUPC.woff');
}

@font-face {
  font-family: 'Tahoma';
  src: url('./assets/fonts/Tahoma.woff');
}

@font-face {
  font-family: 'TH Sarabun New';
  src: url('./assets/fonts/THSarabunNew.woff');
}

@font-face {
  font-family: 'Arial';
  src: url('./assets/fonts/Arial.woff');
}

@font-face {
  font-family: 'Bodoni MT';
  src: url('./assets/fonts/bod_r.woff');
}

@font-face {
  font-family: 'Comic Sans MS';
  src: url('./assets/fonts/Comic\ Sans\ MS.woff');
}

@font-face {
  font-family: 'Cooper Black';
  src: url('./assets/fonts/Cooper\ Black\ W01.woff');
}

@font-face {
  font-family: 'Maiandra GD Regular';
  src: url('./assets/fonts/MaiandraGDRegular.woff');
}

@font-face {
  font-family: 'MS Sans Serif';
  src: url('./assets/fonts/Microsoft\ Sans\ Serif\ V2.woff');
}

@font-face {
  font-family: 'Palatino Linotype';
  src: url('./assets/fonts/Palatino\ Linotype\ W02.woff');
}

@font-face {
  font-family: 'Segoe UI';
  src: url('./assets/fonts/segoe-ui-webfont.woff');
}

@font-face {
  font-family: 'Tempus Sans ITC Regular';
  src: url('./assets/fonts/TEMPSITC.woff');
}

@font-face {
  font-family: 'Times New Roman';
  src: url('./assets/fonts/Times\ New\ Roman\ PS.woff');
}

@font-face {
  font-family: 'Tw Cen MT';
  src: url('./assets/fonts/TwCenMT.woff');
}

@font-face {
  font-family: 'Verdana';
  src: url('./assets/fonts/Verdana\ V2\ V1.woff');
}

@font-face {
  font-family: 'Helvethaica';
  src: url('./assets/fonts/helvetica.woff');
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-weight: 300;
  margin: 0;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='number'],
textarea,
select {
  height: 45px;
  border: 1px solid #adb5bd;
  margin: 5px 0;
  padding: 4px 10px;
  width: 100%;
  background: none;
  background-color: #ffffff;
  border-radius: 5px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 1em;
  font-family: inherit;
  font-weight: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

input,
select {
  height: 45px;
  // max-width: 280px;
}

input[type='text']:read-only {
  background-color: #c0c0c0;
}

input[type='tel']:read-only {
  background-color: #c0c0c0;
}

textarea {
  height: 60px;
}

input::placeholder,
textarea::placeholder {
  color: #777;
  font-size: 0.9em;
  font-style: italic;
}

input:focus,
textarea:focus {
  border-color: #666;
}

.col-center {
  float: none;
  margin: 0 auto;
}
.block {
  text-align: center;
  background: #c0c0c0;
  border: #a0a0a0 solid 1px;
}
.block:before {
  content: '\200B';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.centered {
  display: inline-block;
  vertical-align: middle;
  width: 300px;
  padding: 10px 15px;
  border: #a0a0a0 solid 1px;
  background: #f5f5f5;
}
select {
  padding: 5px 10px;
  -webkit-appearance: menulist;
  font-size: 16px;
}
.title {
  background-color: #222;
  padding: 50px 20px;
  color: white;
  text-align: center;
  font-size: 20px;
}
.helptext {
  display: block;
  font-size: 16px;
  font-weight: normal;
  color: #ccc;
  padding-top: 7px;
}
.error_message {
  font-size: 14px;
  padding-left: 15px;
  padding-bottom: 5px;
}

.survey_button {
  opacity: 0.9;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  width: 180px;
  height: 50px;
  margin: 10px 5px;
  white-space: nowrap;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: inherit;
  font-family: inherit;
  border: 0;
  flex: 1 1 auto;
  min-width: 0;
}
.survey_button:focus {
  outline: 0;
}
.outer {
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: cover;
}
.inner {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.survey {
  background-color: #fff;
  margin: 80px auto;
  width: 100%;
  height: calc(100% - 160px);

  #thank_you_page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  display: block;
}
.power_by {
  width: 184px;
  height: 25px;
  margin: 0 20px 15px 0;
}

.survey_button:hover {
  opacity: 1;
}

/* Ranking style */
.ui-sortable > li {
  cursor: move;
  text-align: center;
  list-style: none;
}
/* Matrix Ranking Style */
.tb-rank {
  color: #333333;
  width: 95%;
  table-layout: fixed;
  margin-left: auto;
  margin-right: auto;
}
.tb-rank th {
  font-weight: normal;
  text-align: center;
}
.tb-rank td {
  border: 0px;
  text-align: left;
  padding: 5px;
}
.tb-rank tr:nth-child(even) {
  background-color: #f2f2f2;
}

.choice {
  padding: 0;
}
.choice > label label {
  cursor: pointer;
}
/* Customize the label (the container) */
.choice > label {
  display: block;
  min-height: 25px;
  padding: 8px;
  text-align: inherit;
  //position: relative;
  margin: 0;
  // cursor: pointer;
  font-size: 1em;
  font-weight: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  line-height: 1.4;
}

// .choice > label:hover {
//   background: #ccc;
// }

.checkmark,
.choice_content {
  vertical-align: top;
}

.choice > input:first-of-type {
  display: none;
}

.choice > label > .checkmark {
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  border: 1px solid #333;
}

.choice > input[type='radio'] ~ label > .checkmark {
  border-radius: 50%;
}

// .choice > input ~ label:hover > .checkmark {
//   background-color: #ccc;
// }

.choice > label > .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.choice > input:checked ~ label > .checkmark:after {
  display: block;
}

.choice > input[type='radio'] ~ label > .checkmark:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #333;
}
.choice > input[type='checkbox'] ~ label > .checkmark:after {
  display: none;
}
.choice > input[type='checkbox']:checked ~ label > .checkmark:after {
  display: block;
  left: 7px;
  top: 2px;
  width: 5px;
  height: 11px;
  border: solid #333;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.choice_content {
  display: inline-block;
  margin-left: 8px;
  max-width: calc(100% - 30px);
  white-space: normal;
}

.sub_question_content > *:first-child,
.choice_content > *:first-child {
  margin-top: 0;
}

.sub_question_content > *:last-child,
.choice_content > *:last-child {
  margin-bottom: 0;
}

.append_text_wrapper {
  display: none;
}

.choice > input:checked ~ label > .append_text_wrapper {
  display: block;
}

.label_wrapper,
.choice_header_group {
  display: none;
}

.sub_questions {
  display: block;
}
.sub_question {
  display: block;
  margin-top: 10px;
}

.sub_question:first-child {
  display: none;
}

.sub_question_header {
  font-size: 1.1em;
}

.question.rating .choice label .rating_label_content::before {
  content: ' ';
}

.question.rating.smiley .choices,
.question.rating.star .choices {
  display: inline-block;
}

.question.rating.smiley .choice_group,
.question.rating.star .choice_group {
  display: inline-block;
  white-space: nowrap;
  margin-top: 20px;
}

.question.rating.smiley .choice,
.question.rating.star .choice {
  display: inline-block;
  width: 55px;
  text-align: center;
}

.question.rating.smiley label,
.question.rating.star label {
  white-space: normal;
  padding: 0;
}

.question.rating.smiley .checkmark,
.question.rating.star .checkmark {
  display: block;
  border: 0;
  width: 55px;
  height: 55px;
}

.question.rating.smiley .checkmark::after,
.question.rating.star .checkmark::after {
  display: none;
}

.question.rating.star .choices {
  direction: rtl;
}

.question.rating.smiley .choice,
.question.rating.star .choice {
  margin-top: 0px;
}

.question.rating.smiley .choice_content,
.question.rating.star .choice_content {
  display: inline-block;
  font-size: 0.8em;
  max-width: unset;
  margin: 0;
  width: 100%;
}

.question.rating.smiley .checkmark::before,
.question.rating.star .checkmark::before {
  content: ' ';
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.choiceinput label {
  vertical-align: middle;
  margin-right: 10px;
  width: 30%;
  word-wrap: break-word;
}

@media (min-width: 480px) {
  .choices,
  .choiceinput,
  .sub_questions {
    display: inline-table;
    table-layout: fixed;
    width: 100%;
  }

  .sub_question > * {
    border-bottom-width: 1px;
    border-bottom-color: #f0f0f0;
    border-bottom-style: solid;
  }

  .choice_header_group,
  .choice_group,
  .sub_question,
  .sub_question:first-child {
    display: table-row;
  }

  .choice_header,
  .choice,
  .choiceinput,
  .sub_question_header {
    display: table-cell;
  }

  .choice_header > *,
  .choice > *,
  .sub_question_header > *,
  .choice > label {
    padding: 8px 5px 7px;
  }

  .sub_question .choice,
  .sub_question_header,
  .question.rating .choice,
  .choice_header {
    vertical-align: middle;
  }

  .sub_question .choice,
  .question.rating .choice,
  .choice_header {
    text-align: center;
  }
  .question.rating.scale .choice label,
  .question.rating.nps .choice label {
    transform: rotate(180deg);
  }

  .choice_header,
  .choice {
    width: 100%;
  }

  .sub_question_header {
    width: 300%;
  }

  .question.rating .choice_content,
  .sub_question .choice .choice_content {
    display: none;
  }

  .rating .label_content {
    display: inline-block;
  }

  .choice_header .choice_content {
    margin: 0;
    max-width: initial;
  }

  .rating .label_wrapper {
    display: block;
  }

  .rating .label {
    display: inline-block;
    padding: 10px 0;
  }

  .rating .label:first-child {
    float: left;
  }
  .rating .label:last-child {
    float: right;
  }

  .sub_question .choice label,
  .question.rating .choice label {
    white-space: normal;
  }

  // .sub_question .choice label {
  //   transform: rotate(180deg);
  // }

  // .sub_question .choice label > *,
  .question.rating.nps .choice label > *,
  .question.rating.scale .choice label > * {
    transform: rotate(180deg);
  }

  .question.rating .choice_content,
  .sub_question:first-child .choice .choice_content {
    display: inline-block;
    margin: 0px;
    padding-bottom: 8px;
    max-width: initial;
    width: 100%;
  }

  .question.rating .choice label .rating_label_content {
    display: none;
  }

  .question.rating.smiley .choice,
  .question.rating.star .choice {
    width: 75px;
  }

  .question.rating.smiley .checkmark,
  .question.rating.star .checkmark {
    display: block;
    border: 0;
    width: 75px;
    height: 75px;
  }

  .question.rating.star .checkmark::before,
  .question.rating.smiley .checkmark::before {
    width: 60px;
    height: 60px;
  }
}

.survey_buttons {
  // margin-top: 25px;
  // margin-bottom: 15px;
  text-align: center;
}
.sub_question_choice {
  display: table-cell;
  width: 100%;
}
.sub_question_choice .choice_content {
  display: none;
}
.sub_question_choice input[type='text'] {
  width: 80%;
}
.sub_question_choice input[type='tel'] {
  width: 80%;
}
.ranking_element {
  width: 30%;
  margin: 0 auto;
}
.ranking_element .ui-icon {
  margin-top: 5px;
}
.sub_question_choice select {
  width: 100%;
  height: 45px;
}
@media (max-width: 768px) {
  .sub_question:first-child {
    visibility: visible;
  }
}
/* Extra Small Devices, Phones */
@media (max-width: 992px) {
  .choiceinput label {
    vertical-align: middle;
    margin-right: 0px;
    width: 100%;
    word-wrap: break-word;
  }
  /* Thank You */
  .thank > img {
    width: 160px;
  }
  .thank > .title {
    font-size: 30px;
  }
  .thank > .sub {
    font-size: 17px;
    padding-top: 10px;
  }
  .sub_question_choice {
    display: block;
    min-width: 200px;
  }
  .sub_question .choice_content {
    display: block;
  }
  .sub_question_choice input[type='text'] {
    width: 100%;
  }
  .sub_question_choice input[type='tel'] {
    width: 100%;
  }

  // .sub_question:first-child{
  //   visibility: hidden;
  // }
  .ranking_element {
    width: initial;
    margin: 0 auto;
  }
}

.page_nav {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-top: 4%;
}
.survey_buttons {
  display: flex;
  flex-flow: row nowrap;
}

.survey_button:first-child {
  margin-left: 10px;
}
.survey_button:last-child {
  margin-right: 10px;
}

.survey_button.button_start {
  flex-basis: 200px;
}

@media (min-width: 480px) {
  .survey_buttons {
    justify-content: center;
  }
  .survey_button {
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .choiceinput label {
    vertical-align: middle;
    margin-right: 0px;
    width: 100%;
    word-wrap: break-word;
  }
  .survey {
    width: 100%;
    margin: 40px auto 40px;
    height: calc(100% - 80px);
  }
  .welcome_title {
    font-size: 2em;
  }
}

@media (min-width: 720px) {
  .survey {
    width: 90%;
    max-width: 960px;
    min-width: 720px;
  }
}

.rating_table {
  margin: 0 auto;
}
.rating_table th {
  text-align: center;
}
.rating_table td {
  padding: 5px;
}

.survey_language_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 15px 0 0;
}

select.survey_language {
  font-size: 0.8em;
  text-align: center;
  height: 30px;
  padding: 0 10px;
  background: transparent;
  cursor: pointer;
  color: #999;
  border: 1px solid #aaa;
  /*for firefox*/
  -moz-appearance: none;
  /*for chrome*/
  -webkit-appearance: none;
}
/*for IE10*/
select.survey_language ::-ms-expand {
  display: none;
}
.m_slt {
  margin: 0 auto;
}

.question_separator {
  height: 40px;
}

.question:first-child .question_separator,
.question ~ .question .question_separator {
  height: 0;
}

.question_title {
  font-size: 1.2em;
}

.question_description {
  padding: 0 20px;
  display: inline-block;
  width: 80%;
  // text-align: initial;
}

.question_helptext {
  font-size: 0.9em;
  color: #777;
  font-style: italic;
  margin: 8px 20px;
}

.page_body {
  padding: 0 4%;
  margin: 1em 0;
  min-height: 300px;
}
.page {
  padding: 4% 0;
  position: relative;

  &.FXBooth2 {
    .page_body {
      .choices {
        .choice_group {
          .choice > label {
            height: 35px !important;
          }

          .choice {
            label {
              span.choice_content {
                @media (max-width: 450px) {
                  margin: 0;
                  padding: 5px 0 !important;
                }
              }
            }

            &:first-child {
              label {
                @media (max-width: 320px) {
                  border-radius: 10px 0px 0px 10px !important;
                }
              }
            }
            &:last-child {
              label {
                border-radius: 10px 0px 0px 10px !important;
                @media (max-width: 320px) {
                  border-radius: 0px 10px 10px 0px !important;
                }
              }
            }
          }
        }
      }

      .question.rating.scale
        > .question_element
        > .choices
        > .choice_group
        > .choice
        > label
        > .choice_content {
        width: 16px !important;
        height: 28px !important;
        line-height: normal !important;
        display: block !important;
        padding: 5px 0 !important;

        @media (max-width: 320px) {
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 30px !important;
          height: 20px !important;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .survey_button.button_previous,
  .survey_button.button_next {
    width: 130px !important;
    height: 50px !important;
  }
}

p {
  margin: 0.5em 0;
}

b {
  font-weight: 600;
}
.logo_wrapper {
  width: 100%;
}
.logo {
  background-size: cover;
  display: inline-block;
}
.welcome_title {
  text-align: center;
  font-size: 2.5em;
}
.page_title {
  margin: 10px 0;
  padding: 5px 4%;
  text-align: left;
  color: #333;
  font-size: 1.2em;
  background: rgb(236, 236, 236);
}

.question.text_multiline .question_helptext,
.question.text_singleline .question_helptext,
.question.text_email .question_helptext,
.question.numeric .question_helptext {
  display: none;
}

.question_separator {
  margin-top: 0px;
}

.question ~ .question .question_separator {
  margin-top: 50px;
}

.question_element {
  margin: 0;
  padding: 0 20px;
}

.inlude_other_text_wrapper {
  display: none;
}

.choice > input:checked ~ label > .inlude_other_text_wrapper {
  display: block;
}
[name='textarea'] {
  resize: vertical;
}
.datetime {
  cursor: pointer;
}

.datecomponent .date_day {
  width: 60px;
  text-align: center;
  line-height: 24px;
  height: 40px;
}
.datecomponent .date_month {
  height: 40px;
  width: 100px;
}
.datecomponent .date_year {
  width: 60px;
  line-height: 24px;
  text-align: center;
  height: 40px;
}
@media (max-width: 480px) {
  .datetime .question_element,
  .datecomponent .question_element {
    padding: 0;
  }
  .datecomponent .date_day {
    width: 42px;
  }
}
.checkmark {
  cursor: pointer;
}
.react-datepicker__input-container > input:read-only {
  background: transparent;
  text-align: center;
  cursor: pointer;
}
.react-datepicker__input-container {
  position: relative;
}
.react-datepicker__input-container::after {
  content: '\1F4C5';
  left: 175px;
  top: 12px;
  position: absolute;
}
.react-datepicker__year-select,
.react-datepicker__month-select {
  width: 100%;
}

// .choiceinput input[type="text"]{
//   display: block;
// }
.drag-handle {
  display: inline-block;
  &::after {
    content: '\2661';
    font-size: 20px;
    vertical-align: middle;
  }
}
.sort-list {
  cursor: row-resize;
}
.sort-item {
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
}
.progress-bar {
  width: 100%;
  border: 1px solid gray;
  height: 24px;
  display: none;
  .progress-bar-status {
    height: 100%;
    display: inline-block;
  }
}

/*#### Survey ####*/

.question_element {
  padding: 0 15px;
}
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='number'],
textarea,
select,
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
  // max-width: unset;
}

// wisdom fixed
[id='F1273241-EFB2-4415-B2FB-A029517A2D88'] {
  text-align: center;
}

/*#### End Survey ####*/
