.outer-index {
  display: table;
  height: 100%;
  position: absolute;
  // background: linear-gradient(to bottom,#0099cc 0%,#00ccff 100%);
}
.inner-index {
  display: table-cell;
  vertical-align: middle;
}
.fb-container-1 {
  width: 100%;
  background-color: #37a0d9;
  border-radius: 6px;
  box-shadow: 0 14px 25px -2px rgba(0, 0, 0, 0.14);
  margin-bottom: 5%;
  position: fixed;
  overflow: hidden;
  text-align: center;
  height: 100%;
  padding-top: 5%;
  left: 0;
}
.fb-container-1 > img {
  width: 35%;
  padding-top: 30px;
  padding-bottom: 30px;
  min-width: 270px;
}
.fb-container-1 > .fb-title {
  color: #fff;
  font-weight: bold;
  font-size: 32px;
  padding-bottom: 20px;
}
.fb-container-1 > .sub-title {
  color: #eee;
  font-size: 22px;
  padding-bottom: 100px;
}

/* Extra Small Devices, Phones */
@media (max-width: 992px) {
  .fb-container-1 > .title {
    font-size: 26px;
  }
  .fb-container-1 > .sub-title {
    font-size: 18px;
    padding-bottom: 60px;
  }
}
