.expire {
  padding-bottom: 50px;
}

.expire-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.expire-container {
  width: 100%;
  background-color: #fff;
  flex: 1;
  margin: 0 auto;
}

.expire .triangle {
  text-align: center;
  margin-top: 20px;
}

.expire .text {
  display: block;
  margin-top: 20px;
}

.expire .t2 {
  font-size: 30px;
  color: #001c20;
  text-align: center;
}
